import { FC, useEffect, useMemo, useState } from 'react';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import ModalDefault from '../../../ModalDefault/ModalDefault';
import FormatPhone from '../../../../utils/FormatPhone';
import FormatCpf from '../../../../utils/FormatCpf';
import Skeleton from 'react-loading-skeleton';
import { ToastSettings } from '../../../ToastSettings';
import { CampaignsImportsService } from '../../../../services/Campaigns/CampaignsImports';
import './ModalCampaignsView.scss';
import FormataData from '../../../../utils/FormataData';
import moment from 'moment';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import AppPagination from '../../../AppPagination/AppPagination';
import { PagesPaginateModel } from '../../../../models/PagesPaginate';
import FormataMoeda from '../../../../utils/FormataMoeda';
import ExportButtons from '../../../ExportButtons/ExportButtons';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { usePaginate } from '../../../../providers/PaginateProvider';
import { ProductsEnum } from '../../../../models/ProductsEnum';
import { ProductsAcronymsEnum } from '../../../../models/Products/ProductsAcronymsEnum';

interface ModalCampaignsViewProps {
  show: boolean;
  onClose?: any;
  importCampaign: any;
}

ChartJS.register(ArcElement, Legend, ChartDataLabels);

const ModalCampaignsView: FC<ModalCampaignsViewProps> = ({ show, onClose, importCampaign }) => {
  const [imports, setImports] = useState<any | null>(null);
  const [importsReport, setImportsReport] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false);
  const [isLoadingReport, setIsLoadingReport] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [paramsDetails, setParamsDetails] = useState<any | null>(null);
  const [pagesDetails, setPagesDetails] = useState<PagesPaginateModel | null>(null);
  const [perPage] = useState<number[]>([10, 25, 50, 100]);
  const [page, setPage] = useState<number>(1);
  const [selectedHasSimulation, setSelectedHasSimulation] = useState<boolean>(false);
  const [selectedHasVisited, setSelectedHasVisited] = useState<boolean>(false);

  const campaignsImportsService = useMemo(() => new CampaignsImportsService(), []);
  const { handlePaginateDetails } = usePaginate();

  const handleParams = (pageNumber: number, hasSimulation: boolean = false, hasVisited: boolean = false) => {
    setIsLoading(true);
    setParamsDetails(
      (current: any) => {
        return { ...current, hasSimulation, page: pageNumber, hasVisited };
      }
    );
    setPage(pageNumber);
  };

  const getImports = async (params: any) => {
    try {
      const [_Response, _Error] = await campaignsImportsService.list(params);
      if (!!_Error) {
        ToastSettings(_Error, 'bottom-center', 'error');
        return false;
      }

      setImports(_Response.data);
      setPagesDetails(_Response.pages);

      return true;
    } catch (e) {
      console.warn(e);
    }
  }

  const getReports = async (importId: number) => {
    try {
      const [_Response, _Error] = await new CampaignsImportsService().report({ importId });

      if (!!_Error) {
        ToastSettings(_Error, 'bottom-center', 'error');
        return false;
      }

      setImportsReport(_Response.data);

      return true;

    } catch (err) {
      console.warn(err);
    }
  };

  useEffect(() => {
    setImportsReport([]);
    setPagesDetails(null);
    setIsLoadingReport(true);

    if (show) {
      setPage(1);
      setSelectedHasSimulation(false);
      setSelectedHasVisited(false);

      setParamsDetails({ importId: importCampaign?.imports?.id, hasSimulation: false, hasVisited: false, page: 1 });

      getReports(importCampaign?.imports?.id).then(() => {
        setIsLoadingReport(false);
      });
    }
  }, [show]);

  useEffect(() => {
    if (show && importCampaign?.imports?.id > 0) {
      setImports([]);
      setIsLoading(true);
    }
  }, [show])

  useEffect(() => {
    if (show && paramsDetails) {
      getImports(paramsDetails).then(() => {
        setError('');
        setIsLoading(false);
      });
    }
  }, [paramsDetails]);

  const renderClassNotifications = (data: any) => {
    if (data?.sent) {
      if (data?.visitedAt) {
        return 'bg-success';
      }
    } else {
      return 'bg-danger';
    }

    return 'bg-default';
  }

  const renderClassSimulations = (data: any) => {
    if (data?.simulation) {
      return 'bg-success';
    } else {
      return 'bg-danger';
    }
  }

  const renderList = () => {
    return (
      <>
        {imports?.length > 0 ? (
          <>
            {imports?.map((item: any, key: number) => (
              <tr key={key}>
                <td className="align-middle text-center" style={{ width: '230px' }}>
                  <p className="text-xs text-secondary mb-0">{item?.id}</p>
                </td>
                <td style={{ width: '25%' }}>
                  <div className="d-flex py-1">
                    <div className="d-flex flex-column justify-content-center">
                      {(item?.name) ? (
                        <h6 className="text-xs text-secondary mb-0"><b>Nome:</b> {item?.name}</h6>
                      ) : ('')}

                      {(item?.cpf) ? (
                        <p className="text-xs text-secondary mb-0"><b>CPF:</b> {FormatCpf(item?.cpf)}</p>
                      ) : ('')}

                      {(item?.cpfRepresentative) ? (
                        <p className="text-xs text-secondary mb-0"><b>CPF Representante:</b> {FormatCpf(item?.cpfRepresentative)}</p>
                      ) : ('')}

                      {(item?.benefitNumber) ? (
                        <p className="text-xs text-secondary mb-0"><b>Num. Benefício:</b> {item?.benefitNumber}</p>
                      ) : ('')}

                      {(item?.email) ? (
                        <p className="text-xs text-secondary mb-0"><b>Email:</b> {item?.email}</p>
                      ) : ('')}

                      {(item?.phone) ? (
                        <p className="text-xs text-secondary mb-0"><b>Telefone:</b> {FormatPhone(item?.phone)}</p>
                      ) : ('')}

                      {(item?.phone2) ? (
                        <p className="text-xs text-secondary mb-0"><b>Telefone 2:</b> {FormatPhone(item?.phone2)}</p>
                      ) : ('')}

                      {(item?.birthday) ? (
                        <p className="text-xs text-secondary mb-0"><b>Data Nasc.:</b> {FormataData(item?.birthday)}</p>
                      ) : ('')}

                      {(item?.amountReleased > 0) ? (
                        <p className="text-xs text-secondary mb-0"><b>Valor Liberado:</b> {FormataMoeda(item?.amountReleased)}</p>
                      ) : ('')}

                      {(item?.covenantName) ? (
                        <p className="text-xs text-secondary mb-0"><b>Convênio:</b> {item?.covenantName}</p>
                      ) : ('')}

                      {(item?.covenantId) ? (
                        <p className="text-xs text-secondary mb-0"><b>ID Convênio:</b> {item?.covenantId}</p>
                      ) : ('')}

                      {(item?.cep > 0) ? (
                        <p className="text-xs text-secondary mb-0"><b>CEP:</b> {item?.cep}</p>
                      ) : ('')}
                    </div>
                  </div>
                </td>
                {importCampaign?.parameterization !== 2 && (
                  <td className="align-middle">
                    <div className="d-flex align-items-center justify-content-center gap-5 text-center">
                      {item?.reports.map((report: any, key2: number) => (
                        <div className="status-wrapper" key={key2}>
                          <OverlayTrigger
                            placement={'top'}
                            overlay={
                              <Tooltip>
                                Enviado para {report?.destiny || '-'}
                              </Tooltip>
                            }
                          >
                            <div
                              className={`badge badge-sm mb-2 ${renderClassNotifications(report)}`}
                              key={key2}
                            >
                              <div className="d-flex">
                                <div className="me-2">
                                  {report?.sent ? (
                                    <>
                                      {report?.visitedAt ? (
                                        <i className="fa-solid fa-check-double"></i>
                                      ) : (
                                        <i className="fa-solid fa-check"></i>
                                      )}
                                    </>
                                  ) : (
                                    <i className="fa-solid fa-xmark"></i>
                                  )}
                                </div>

                                <div>{report?.channel}</div>
                              </div>
                            </div>
                          </OverlayTrigger>

                          <p className="text-xs text-secondary mb-0">{report?.message}</p>

                          {report?.visitedAt && (
                            <p className="text-xs text-secondary mt-2 mb-0">Visitou em <b>{moment(report?.visitedAt).format('DD/MM/YYYY HH:mm:ss')}</b></p>
                          )}
                        </div>
                      ))}
                    </div>
                  </td>
                )}

                {importCampaign?.parameterization !== 1 && (
                  <td className="align-middle">
                    <div className="d-flex align-items-center justify-content-center gap-5 text-center">
                      {item?.simulations.map((simulate: any, key2: number) => (
                        <div className="status-wrapper" key={key2}>
                          <div className="d-flex justify-content-center mb-3">
                            <OverlayTrigger
                              placement={'top'}
                              overlay={
                                <Tooltip>
                                  Simulação realizada em {moment(new Date(simulate?.createdAt)).format("DD/MM/YYYY HH:mm:ss") || ""}
                                </Tooltip>
                              }
                            >
                              <>
                                <div className="me-3">
                                  <img
                                    src={`${process.env.PUBLIC_URL}/assets/img/products/${simulate?.financial}.png`}
                                    className={`img-responsive ${simulate?.financial?.toLowerCase()}`}
                                  />
                                </div>

                                <div
                                  className={`badge badge-sm ${renderClassSimulations(simulate)}`}
                                  key={key2}
                                >
                                  <div className="d-flex">
                                    <div className="me-2">
                                      {simulate?.simulation ? (
                                        <>
                                          {simulate?.visitedAt ? (
                                            <i className="fa-solid fa-check-double"></i>
                                          ) : (
                                            <i className="fa-solid fa-check"></i>
                                          )}
                                        </>
                                      ) : (
                                        <i className="fa-solid fa-xmark"></i>
                                      )}
                                    </div>

                                    <div>{simulate?.product}</div>
                                  </div>
                                </div>
                              </>
                            </OverlayTrigger>
                          </div>

                          {!simulate?.simulation && (
                            <p className="text-xs text-secondary mb-0">{simulate?.message}</p>
                          )}

                          {(simulate?.valueMax > 0 || simulate?.valueMaxCard > 0) && (
                            <>
                              <p className="text-xs text-secondary mt-2 mb-0">Valor Disponível <b>{FormataMoeda(simulate?.valueMax)}</b></p>

                              {simulate?.product !== ProductsAcronymsEnum.INSS && (
                                <p className="text-xs text-secondary mt-2 mb-0">Valor Cartão Disponível <b>{FormataMoeda(simulate?.valueMaxCard)}</b></p>
                              )}

                              {simulate?.debitBalance > 0 && (
                                <p className="text-xs text-secondary mt-2 mb-0">Valor Utilizado <b>{FormataMoeda(simulate?.debitBalance)}</b></p>
                              )}

                              {simulate?.product === ProductsAcronymsEnum.INSS && (
                                <p className="text-xs text-secondary mt-2 mb-0">Número do Contrato <b>{simulate?.inss?.contractId}</b></p>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </>
        ) : (
          <tr>
            <td className="text-sm text-center" colSpan={4}>Não há registros</td>
          </tr>
        )}
      </>
    );
  }

  const handleExcel = async (importId: number) => {
    setError('');
    setIsLoadingFile(true);

    const [_Response, _Error] = await campaignsImportsService.export({ importId, hasSimulation: selectedHasSimulation, hasVisited: selectedHasVisited });

    if (_Error) {
      setIsLoadingFile(false);
      setError(_Error || 'Não foi possivel solicitar o relatório.');
      return false;
    }

    toast.success(_Response?.message || 'Relatório gerado com sucesso!');
    setIsLoadingFile(false);
  }

  const handleChange = (event: any) => {
    setIsLoading(true);

    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));

    handleParams(parseInt(value));
  };

  const handleChangePerPage = (perPage: number) => {
    setIsLoading(true);

    setParamsDetails((current: any) => { return { ...current, page, perPage }; })
  };

  const handleChangeHasSimulation = (event: any) => {
    setPage(1);
    setIsLoading(true);

    let { checked } = event.target;

    handleParams(1, checked, selectedHasVisited);
  };

  const handleChangeHasVisited = (event: any) => {
    setPage(1);
    setIsLoading(true);

    let { checked } = event.target;

    handleParams(1, selectedHasSimulation, checked);
  };

  return (
    <ModalDefault
      className="ModalCampaignsView"
      title={'Detalhes da Campanha'}
      show={show}
      sizeModal={'xxl'}
      onClose={onClose}
      buttonText={'Salvar'}
      disabledSubmit={isLoading}
    >
      <div data-testid="ModalCampaignsView">
        <div className="row">
          <div className="col-4">
            <div className="card">
              <div className="card-body card-graph">
                {!isLoadingReport ? (
                  <>
                    <h6 className="mb-0"><b>Taxa de Conversão</b></h6>

                    <div className="row mt-2">
                      {importCampaign?.sendNotifications == 1 && (
                        <div className="col">
                          <div>
                            <Doughnut
                              data={
                                {
                                  labels: ['Clientes', 'Visitas'],
                                  datasets: [{
                                    label: "",
                                    borderWidth: 2,
                                    backgroundColor: ['#5e72e4', '#2dce89'],
                                    data: [
                                      (((importsReport?.totalSend - importsReport?.totalVisited) / importsReport?.totalSend) * 100).toFixed(0),
                                      ((importsReport?.totalVisited / importsReport?.totalSend) * 100).toFixed(0)
                                    ]
                                  }],
                                }
                              }
                              options={
                                {
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  cutout: '50%',
                                  interaction: {
                                    intersect: false,
                                    mode: 'index',
                                  },
                                  plugins: {
                                    tooltip: {
                                      enabled: false
                                    },
                                    datalabels: {
                                      display: true,
                                      color: '#333',
                                      backgroundColor: (data) => {
                                        const value = data.dataset.data[data.dataIndex];
                                        return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                      },
                                      borderRadius: 3,
                                      formatter: (value) => {
                                        if (value < 10) return '';
                                        return value + '%';
                                      },
                                    }
                                  }
                                }
                              }
                              style={{ width: 200, height: 200 }}
                            />
                          </div>
                        </div>
                      )}

                      {importCampaign?.checkAvailableProducts == 1 && (
                        <div className="col">
                          <div>
                            <Doughnut
                              data={
                                {
                                  labels: ['Clientes', 'Tem Saldo'],
                                  datasets: [{
                                    label: "",
                                    borderWidth: 2,
                                    backgroundColor: ['#5e72e4', '#2dce89'],
                                    data: [
                                      (((importsReport?.totalSend - importsReport?.totalSimulate) / importsReport?.totalSend) * 100).toFixed(0),
                                      ((importsReport?.totalSimulate / importsReport?.totalSend) * 100).toFixed(0)
                                    ]
                                  }],
                                }
                              }
                              options={
                                {
                                  responsive: true,
                                  maintainAspectRatio: false,
                                  cutout: '50%',
                                  interaction: {
                                    intersect: false,
                                    mode: 'index',
                                  },
                                  plugins: {
                                    tooltip: {
                                      enabled: false
                                    },
                                    datalabels: {
                                      display: true,
                                      color: '#333',
                                      backgroundColor: (data) => {
                                        const value = data.dataset.data[data.dataIndex];
                                        return (value && parseInt(value.toString()) >= 10) ? '#fff' : '';
                                      },
                                      borderRadius: 3,
                                      formatter: (value) => {
                                        if (value < 10) return '';
                                        return value + '%';
                                      },
                                    }
                                  }
                                }
                              }
                              style={{ width: 200, height: 200 }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div className="text-center">
                    <Skeleton />

                    <div className="row">
                      <div className="col text-end">
                        <Skeleton width={50} />
                      </div>
                      <div className="col text-start">
                        <Skeleton width={50} />
                      </div>
                    </div>

                    <Skeleton circle width={170} height={170} className="mt-2" />
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-8 h-100">
            <div className="row">
              <div className="col-3">
                <div className="card">
                  <div className="card-body p-4 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h2 font-weight-bolder text-gradient text-primary">{importsReport?.totalSend || 0}</span>
                        <h6 className="mb-0 font-weight-bolder">Clientes</h6>
                        <p className="opacity-8 mb-0 text-sm">Meta</p>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="card">
                  <div className="card-body p-4 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h2 font-weight-bolder text-gradient text-primary">{importsReport?.totalVisited || 0}</span>
                        <h6 className="mb-0 font-weight-bolder">Visitas</h6>
                        <p className="opacity-8 mb-0 text-sm">Alcance</p>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="card">
                  <div className="card-body p-4 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h2 font-weight-bolder text-gradient text-primary">{importsReport?.totalSimulate || 0}</span>
                        <h6 className="mb-0 font-weight-bolder">Tem Saldo</h6>
                        <p className="opacity-8 mb-0 text-sm">Alcance</p>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="card">
                  <div className="card-body p-4 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h2 font-weight-bolder text-gradient text-primary">{FormataMoeda(importsReport?.valueTotal)}</span>
                        <h6 className="mb-0 font-weight-bolder">Valor Total</h6>
                        <h6 className="opacity-8 mb-0 text-xs">Campanha</h6>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={40} />

                        <Skeleton width={90} className="mt-2" />

                        <Skeleton width={80} />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="card mt-3">
                  <div className="card-body p-3 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h3 font-weight-bolder text-gradient text-primary">{importsReport?.totalProcessSimulate}</span>
                        <h6 className="text-sm mb-0 font-weight-bolder">Total Processado</h6>
                        <h6 className="opacity-8 mb-0 text-xs">Simulações</h6>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={30} />

                        <Skeleton width={90} height={12} className="mt-2" />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-3">
                <div className="card mt-3">
                  <div className="card-body p-3 text-center">
                    {!isLoadingReport ? (
                      <>
                        <span className="h3 font-weight-bolder text-gradient text-primary">{importsReport?.totalProcessReport}</span>
                        <h6 className="text-sm mb-0 font-weight-bolder">Total Processado</h6>
                        <h6 className="opacity-8 mb-0 text-xs">Envios</h6>
                      </>
                    ) : (
                      <>
                        <Skeleton width={60} height={30} />

                        <Skeleton width={90} height={12} className="mt-2" />
                      </>
                    )}
                  </div>
                </div>
              </div>

              <div className="col-6 mt-3">
                <div className="card h-100">
                  <div className="card-body p-4">
                    <div className="row">
                      <div className="col">
                        {!isLoadingReport ? (
                          <h6 className="title mb-0 w-100">
                            <b>Tipo da Campanha</b>

                            {importCampaign?.sendTo == 1 ? (
                              <>
                                {importCampaign?.userGroups?.map((userGroup: any, key: number) => (
                                  <span className="badge badge-sm bg-gradient-info m-1" key={key}>{userGroup?.name}</span>
                                ))}
                              </>
                            ) : (
                              <span className="badge badge-sm bg-gradient-warning m-1">Importação</span>
                            )}
                          </h6>
                        ) : (
                          <>
                            <div className="row align-items-center">
                              <div className="col text-start">
                                <Skeleton width={120} />
                              </div>
                              <div className="col text-end">
                                <Skeleton width={80} height={25} />
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="card">
              <div className="table-responsive">
                {pagesDetails && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination
                        pages={pagesDetails}
                        handlePaginate={handlePaginateDetails}
                        params={paramsDetails}
                        setParams={setParamsDetails}
                        exportDiv={
                          <div className="d-flex align-items-center gap-3">
                            {importCampaign?.parameterization !== 1 && (
                              <div className="d-flex gap-2 align-items-center">
                                <label className='form-control-label mb-0'>Somente Simulações<br />Efetuadas</label>
                                <label className="custom-toggle">
                                  <input
                                    id="hasSimulation"
                                    type="checkbox"
                                    name="hasSimulation"
                                    checked={selectedHasSimulation}
                                    onClick={() => setSelectedHasSimulation(!selectedHasSimulation)}
                                    onChange={handleChangeHasSimulation}
                                  />
                                  <span className="custom-toggle-slider" />
                                </label>
                              </div>
                            )}

                            {importCampaign?.parameterization !== 2 && (
                              <div className="d-flex gap-2 align-items-center">
                                <label className='form-control-label mb-0'>Somente Envios<br />Visitados</label>
                                <label className="custom-toggle">
                                  <input
                                    id="hasVisited"
                                    type="checkbox"
                                    name="hasVisited"
                                    checked={selectedHasVisited}
                                    onClick={() => setSelectedHasVisited(!selectedHasVisited)}
                                    onChange={handleChangeHasVisited}
                                  />
                                  <span className="custom-toggle-slider" />
                                </label>
                              </div>
                            )}

                            <ExportButtons
                              getExcel={() => handleExcel(importCampaign?.imports?.id)}
                              isLoading={isLoadingFile}
                              error={error}
                            />

                            <input
                              type='text'
                              className='form-control text-center'
                              value={page}
                              onChange={handleChange}
                              min={1}
                              max={pagesDetails?.last}
                              style={{ width: 60, height: 35 }}
                            />
                          </div>
                        }
                        style={{ backgroundColor: '#fff', borderRadius: '1rem 1rem 0 0' }}
                      />
                    </nav>
                  </div>
                )}

                <table className="table align-items-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">#</th>
                      <th className="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" style={{ width: '25%' }}>Cliente</th>
                      {importCampaign?.parameterization !== 2 && (
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Canais Enviados</th>
                      )}
                      {importCampaign?.parameterization !== 1 && (
                        <th className="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">Produtos Simulados</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td className="text-center"><Skeleton width={20} /></td>
                        <td><Skeleton /></td>
                        {importCampaign?.parameterization !== 2 && (
                          <td className="text-center"><Skeleton width={200} /></td>
                        )}
                        {importCampaign?.parameterization !== 1 && (
                          <td className="text-center"><Skeleton width={200} /></td>
                        )}
                      </tr>
                    ) : (
                      <>{renderList()}</>
                    )}
                  </tbody>
                </table>

                {pagesDetails && (
                  <div>
                    <nav aria-label="...">
                      <AppPagination
                        pages={pagesDetails}
                        handlePaginate={handlePaginateDetails}
                        params={paramsDetails}
                        setParams={setParamsDetails}
                        exportDiv={
                          <div className="d-flex align-items-center gap-2">
                            <span className="text-sm">Mostrar</span>
                            <select
                              className='form-control text-center'
                              onChange={(e: any) => handleChangePerPage(parseInt(e.target.value))}
                              style={{ width: 60, height: 40 }}
                            >
                              {perPage.map((item: number) => (
                                <option value={item}>{item}</option>
                              ))}
                            </select>
                            <span className="text-sm">registros</span>
                          </div>
                        }
                        style={{ backgroundColor: '#fff', borderRadius: '0 0 1rem 1rem' }}
                      />
                    </nav>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalDefault>
  );
}

export default ModalCampaignsView;
