import { FC, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import { ufs } from '../../../../models/OptionsValues';
import { customStyles } from '../../../../models/SelectCustomStyles';
import { useAuth } from '../../../../providers/AuthProvider';
import Skeleton from 'react-loading-skeleton';

interface FormPreFgtsProps {
  control: any | null;
  errors: any;
  register: any;
  setValue?: any;
  isLoading: boolean;
}

const FormPreFgts: FC<FormPreFgtsProps> = ({ control = null, errors, register, setValue, isLoading }) => {
  const [selectedUf, setSelectedUf] = useState<any | null>(null);
  const { userSimulation, onInvalid } = useAuth();

  useEffect(() => {
    setSelectedUf(ufs?.find((element: any) => element.value === userSimulation?.uf));
  }, [userSimulation]);

  useEffect(() => {
    setValue("uf", selectedUf?.value || null);
  }, [selectedUf]);

  return (
    <div className="FormPreFgts col" data-testid="FormPreFgts">
      <div className="row">
        <div className="col-12 text-start mb-3">
          <label>ESTADO NATAL *</label>
          {!isLoading ? (
            <>
              {control && (
                <Controller
                  name="uf"
                  control={control}
                  render={({ field: { onChange, name, ref } }) => (
                    <Select
                      ref={ref}
                      name={name}
                      isClearable
                      isSearchable
                      options={ufs}
                      placeholder="Selecione..."
                      className={`multisteps-form__input form-control p-0 ${onInvalid(errors?.uf)}`}
                      value={selectedUf || ''}
                      onChange={val => {
                        onChange(val?.value || null);
                        setSelectedUf(val);
                      }}
                      noOptionsMessage={() => 'Não há registros'}
                      styles={customStyles}
                    />
                  )}
                />
              )}
              <ErrorMessage name="Estado Natal" type={errors?.uf?.type} />
            </>
          ) : (
            <Skeleton height={40} />
          )}
        </div>
      </div>
    </div>
  );
}

export default FormPreFgts;
