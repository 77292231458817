import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';

interface SkeletonCardPanelTotalProps { }

const SkeletonCardPanelTotal: FC<SkeletonCardPanelTotalProps> = () => (
  <div className="SkeletonCardPanelTotal" data-testid="SkeletonCardPanelTotal">
    <div className={`card overflow-hidden`}>
      <div className={`card-header p-3 pb-0`}>
        <div className='row'>
          <div className='col'>
            <div className='text-sm mb-0'>
              <p className="text-sm mb-0 text-capitalize text-white"><Skeleton width={120}/></p>
              <h5 className="font-weight-bolder mb-0 text-white"><Skeleton width={70}/></h5>
            </div>
          </div>
        </div>

        <p className="mt-4 mb-2 text-sm">
          <Skeleton width={80}/>
        </p>
      </div>
    </div>
  </div>
);

export default SkeletonCardPanelTotal;
