import {Dispatch, FC, SetStateAction, useEffect} from 'react';
import ModalDefault from '../../ModalDefault/ModalDefault';
import MultiStepsForm from '../../MultiStepsForm/MultiStepsForm';
import StepClients from '../StepClients/StepClients';
import StepBankData from '../StepBankData/StepBankData';
import StepAddressData from '../StepAddressData/StepAddressData';
import StepPersonalData from '../StepPersonalData/StepPersonalData';
import StepProducts from '../StepProducts/StepProducts';
import StepResult from '../StepResult/StepResult';
import {faCircleCheck} from '@fortawesome/free-solid-svg-icons';
import './ModalTyping.scss';
import StepConfirm from '../StepConfirm/StepConfirm';
import {useSimulation} from '../../../providers/Typing/SimulationProvider';
import {LeadAllModel} from "../../../models/Leads/LeadsAll/LeadAllModel";
import Swal from "sweetalert2";
import {useMultiSteps} from "../../../providers/MultiStepsProvider";
import {useAuth} from "../../../providers/AuthProvider";

interface ModalTypingProps {
    show: boolean,
    onClose?: Dispatch<SetStateAction<boolean>>,
    lead?: LeadAllModel,
    canChangeTypist?: boolean
}

const ModalTyping: FC<ModalTypingProps> = ({show, onClose, lead, canChangeTypist}) => {
    const {setSelectAll, setProductsSelected, setSimulatesSelected} = useSimulation();
    const {resetUserSimulation} = useAuth();
    const {setCurrentStep} = useMultiSteps();

    const clearDate = () => {
        setSelectAll(false);
        setProductsSelected([]);
        setSimulatesSelected([]);
        resetUserSimulation('', '');
        setCurrentStep(0);
    }
    const handleNew = () => {
        Swal.fire({
            icon: 'question',
            title: 'Atenção',
            html: 'Deseja iniciar uma nova digitação?',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não'
        }).then((value) => {
            if (value.isConfirmed) {
                clearDate();
            }
        })
    }
    const steps = [
        {
            title: 'Produto',
            descricao: 'Selecione o Produto',
            icon: faCircleCheck,
            children: <StepProducts/>,
            bgCard: '#fff'
        },
        {
            title: 'Cliente',
            descricao: '',
            children: <StepClients lead={lead} handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'
                                   canChangeTypist={canChangeTypist}/>,
            bgCard: '#fff'
        },
        {
            title: 'Resultado',
            descricao: '',
            children: <StepResult handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'/>,
            bgCard: '#f5f5f5',
            class: 'p-0'
        },
        {
            title: <span>Dados<br/>Pessoais</span>,
            descricao: '',
            children: <StepPersonalData handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'/>,
            bgCard: '#fff'
        },
        {
            title: <span>Dados<br/>Endereço</span>,
            descricao: '',
            children: <StepAddressData handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'/>,
            bgCard: '#fff'
        },
        {
            title: <span>Dados<br/>Bancários</span>,
            descricao: '',
            children: <StepBankData handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'/>,
            bgCard: '#fff'
        },
        {
            title: <span>Confirmação</span>,
            descricao: '',
            children: <StepConfirm handleExtraAction={handleNew} textButtonExtraAction='Nova Digitação'/>,
            bgCard: '#fff'
        },
    ];

    useEffect(() => {
        setSelectAll(false);
        setProductsSelected([]);
    }, []);

    useEffect(() => {
        if (!show) {
            clearDate();
        }
    }, [show]);

    return (
        <ModalDefault
            show={show}
            onClose={onClose}
            title={'Digitar Proposta'}
            sizeModal={"xxl"}
            showFooter={false}
            backdrop="static"
        >
            <div className="ModalTyping" data-testid="ModalTyping">
                <div className="container-fluid p-0">
                    <div className="row">
                        <div className="col-12 text-center">
                            <MultiStepsForm steps={steps} navClick={false}/>
                        </div>
                    </div>
                </div>
            </div>
        </ModalDefault>
    );
}

export default ModalTyping;
