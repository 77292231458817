import React, {FC, useCallback, useEffect, useState} from 'react';
import './CardsDashboard.scss';
import CardPanelValue from "../CardPanelValue/CardPanelValue";
import CardPanelTotal from "../CardPanelTotal/CardPanelTotal";
import {useResumoGeral} from "../../../hooks/DashBoardHooks";
import {PrepareData, ToggleGraphType} from "../../../utils/PainelHelpers";
import useSetGrafico from "../../../hooks/useSetGrafico";
import {Chart} from "../../../models/Chart/Chart";
import {ChartOptions} from "../../../models/Chart/ChartOptions";
import moment from "moment";
import {ChartType} from "../../../models/Chart/ChartType";
import CardChartPanelBar from '../CardChartPanelBar/CardChartPanelBar';
import LastUpdateComponent from "../../LastUpdateComponent/LastUpdateComponent";
import {useAuth} from '../../../providers/AuthProvider';
import ModalTyping from '../../Typing/ModalTyping/ModalTyping';
import SkeletonCardPanelValue from '../SkeletonCardPanelValue/SkeletonCardPanelValue';
import SkeletonCardPanelTotal from '../SkeletonCardPanelTotal/SkeletonCardPanelTotal';
import Skeleton from 'react-loading-skeleton';
import SkeletonCardChartPanelBar from '../SkeletonCardChartPanelBar/SkeletonCardChartPanelBar';
import {Col, Row} from 'react-bootstrap';


interface CardsDashboardProps {
}

const CardsDashboard: FC<CardsDashboardProps> = () => {
    const [showModalTyping, setShowModalTyping] = useState<boolean>(false);
    const [canChangeTypist, setCanChangeTypist] = useState<boolean>(false);

    const {userLogado, CanAccess} = useAuth();
    const {isLoading, error, data, isFetching} = useResumoGeral();
    const [showTyping, setShowTyping] = useState<boolean>(false);

    const handleShowModalTyping = () => setShowModalTyping(true);

    const preparaData = useCallback(PrepareData, []);
    const {
        graficoDiario,
        graficoMensal,
        updatedAt,
        graficoTipo,
        setGraficoDiario,
        setGraficoMensal,
        setUpdatedAt,
        setGraficoTipo,
    } = useSetGrafico();

    const preparaDados = useCallback((item: Chart) => {
        const {diario, mensal} = preparaData(item, graficoTipo);

        setGraficoDiario({data: diario, options: ChartOptions.normal});
        setGraficoMensal({data: mensal, options: ChartOptions.normal});
        setUpdatedAt(moment(new Date()));
    }, [graficoTipo, preparaData, setGraficoDiario, setGraficoMensal, setUpdatedAt]);

    useEffect(() => {
        data && preparaDados(data);
    }, [preparaDados, data]);

    useEffect(() => {
        CanAccess('home.botaodigtacao.visualizacao').then((x: boolean) => setShowTyping(x));
        CanAccess('leads.ver_tudo.regra').then((x: boolean) => setCanChangeTypist(x));
    }, []);

    return (
        <div className="CardsDashboard" data-testid="CardsDashboard">
            <div className="row mt-n2">
                <div className="col-xl-8 col-lg-8">
                    <>
                        {error && (
                            <div>Erro ao carregar dados.</div>
                        )}

                        {!isLoading ? (
                            <div className="row gy-3">
                                <div className="col-md-6 col-xl-6 col-sm-12">
                                    <CardPanelValue
                                        value={data?.simulacoes?.valor || 0}
                                        total={data?.simulacoes?.total || 0}
                                        title={'Simulado'}
                                        onClick={(e: any) => e.preventDefault()}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-6 col-sm-12">
                                    <CardPanelValue
                                        value={data?.propostas?.valor || 0}
                                        total={data?.propostas?.total || 0}
                                        title={'Contratado'}
                                        color={'bg-gradient-info'}
                                        onClick={(e: any) => e.preventDefault()}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <CardPanelTotal
                                        total={data?.propostas?.aprovadas || 0}
                                        title={'Propostas Aprovadas'}
                                        onClick={(e: any) => e.preventDefault()}
                                        color={'bg-gradient-dark'}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <CardPanelTotal
                                        total={data?.propostas?.pendentes || 0}
                                        title={'Propostas em Análise'}
                                        onClick={(e: any) => e.preventDefault()}
                                        color={'bg-gradient-warning'}
                                    />
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <CardPanelTotal
                                        total={data?.propostas?.reprovadas || 0}
                                        title={'Propostas Reprovadas'}
                                        onClick={(e: any) => e.preventDefault()}
                                        color={'bg-gradient-danger'}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="row gy-3">
                                <div className="col-md-6 col-xl-6 col-sm-12">
                                    <SkeletonCardPanelValue/>
                                </div>

                                <div className="col-md-6 col-xl-6 col-sm-12">
                                    <SkeletonCardPanelValue/>
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <SkeletonCardPanelTotal/>
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <SkeletonCardPanelTotal/>
                                </div>

                                <div className="col-md-6 col-xl-4 col-sm-12">
                                    <SkeletonCardPanelTotal/>
                                </div>
                            </div>
                        )}
                    </>
                </div>

                <div className="col-xl-4 col-lg-4 mt-lg-0 mt-4">
                    <div className="row h-100">
                        <div className="col-lg-12 h-100">
                            <div className="card p-3 h-100">
                                <div className="overflow-hidden position-relative border-radius-lg bg-cover h-100"
                                     style={{backgroundImage: "url('/assets/img/ivancik.jpg')"}}>
                                    <span className="mask bg-gradient-dark"></span>
                                    <div className="card-body position-relative z-index-1 h-100 p-3">
                                        <h6 className="text-white font-weight-bolder mb-3">Olá {userLogado?.name}!</h6>
                                        <p className="text-white mb-3">Seja incansável e persistente, melhore a
                                            apresentação e encante o cliente em cada detalhe.</p>
                                        {showTyping && (
                                            <button type="button" className="btn btn-round btn-outline-white mb-0"
                                                    onClick={() => handleShowModalTyping()}>
                                                Digitar Nova Proposta <i className="fas fa-arrow-right text-sm ms-1"
                                                                         aria-hidden="true"></i>
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-12" style={{position: 'relative'}}>
                    {!isLoading ? (
                        <>
                            <div className="card-group flex-column flex-xl-row">
                                <div className="toggle-container mt-3 mb-3">
                                    Valor
                                    <label className="custom-toggle">
                                        <input defaultChecked type="checkbox"
                                               onClick={() => setGraficoTipo(ToggleGraphType)}/>
                                        <span className="custom-toggle-slider"/>
                                    </label>
                                    Quantidade
                                </div>

                                <CardChartPanelBar
                                    title={'Gráfico Geral'}
                                    subtitle={`Diário ${graficoTipo === ChartType.COUNT ? 'Quantidade' : 'Valor'}`}
                                    data={graficoDiario?.data}
                                    options={graficoDiario?.options}
                                    isLoading={isFetching}
                                />

                                <CardChartPanelBar
                                    title={'Gráfico Geral'}
                                    subtitle={`Mensais ${graficoTipo === ChartType.COUNT ? 'Quantidade' : 'Valor'}`}
                                    data={graficoMensal?.data}
                                    options={graficoMensal?.options}
                                    isLoading={isFetching}
                                />
                            </div>

                            <LastUpdateComponent updatedAt={updatedAt} error={error}/>
                        </>
                    ) : (
                        <>
                            <div className="card-group flex-column flex-xl-row">
                                <div className="toggle-container mt-3 mb-3">
                                    <Skeleton width={50}/>
                                    <label className="custom-toggle">
                                        <Skeleton width={50} height={22}/>
                                    </label>
                                    <Skeleton width={50}/>
                                </div>

                                <SkeletonCardChartPanelBar/>

                                <SkeletonCardChartPanelBar/>
                            </div>

                            <Row>
                                <Col className="text-right d-flex justify-content-end">
                                    <Skeleton width={180}/>
                                </Col>
                            </Row>
                        </>
                    )}
                </div>
            </div>

            <ModalTyping
                show={showModalTyping}
                onClose={setShowModalTyping}
                canChangeTypist={canChangeTypist}
            />
        </div>
    )
};

export default CardsDashboard;
