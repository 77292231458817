import {BrowserRouter, Route, Routes} from 'react-router-dom';
import ClientsUsers from '../pages/Clients/ClientsUsers/ClientsUsers';
import Home from '../pages/Home/Home';
import Login from '../pages/Login/Login';
import Produtos from '../pages/Products/List/Products';
import {AuthProvider} from '../providers/AuthProvider';
import {AuthenticationGate} from './PrivateRoute';
import ProfileAccess from "../pages/Admin/ProfileAccess/ProfileAccess";
import User from "../pages/Admin/User/User";
import {QueryClient, QueryClientProvider} from 'react-query';
import Cashback from '../pages/Cashback/Cashback/Cashback';
import Page404 from '../pages/Page404/Page404';
import Campaigns from '../pages/Campaigns/Campaigns';
import Unauthorized from "../pages/Unauthorized/Unauthorized";
import AppVersion from "../pages/App/VersoesApp/AppVersion";
import UserGroup from '../pages/Campaigns/UserGroup/UserGroup';
import CashbackRescue from "../pages/Cashback/CashbackRescue/CashbackRescue";
import CashbackConfiguration from "../pages/Cashback/CashbackConfiguration/CashbackConfiguration";
import CustomerCapture from '../pages/CustomerCapture/CustomerCapture';
import HelpArticles from '../pages/HelpArticles/HelpArticles';
import ProposalsTrackError from "../pages/ProposalsTrack/ProposalsTrackError/ProposalsTrackError";
import Company from "../pages/Company/Company";
import {StyleProvider} from "../providers/Style/StyleProvider";
import LogsAuth from '../pages/Clients/LogsAuth/LogsAuth';
import ProposalTrackList from "../pages/ProposalsTrack/ProposalTrackList/ProposalTrackList";
import ProposalsCovenantsTable from '../pages/ProposalsTrack/ProposalsCovenantsTable/ProposalsCovenantsTable';
import ProposalsCovenants from '../pages/ProposalsTrack/ProposalsCovenants/ProposalsCovenants';
import ProposalsCovenantsGroups from '../pages/ProposalsTrack/ProposalsCovenantsGroups/ProposalsCovenantsGroups';
import ProposalsOperations from '../pages/ProposalsTrack/ProposalsOperations/ProposalsOperations';
import ProposalsStatus from '../pages/ProposalsTrack/ProposalsStatus/ProposalsStatus';
import ProposalsSaleOrigin from '../pages/ProposalsTrack/ProposalsSaleOrigin/ProposalsSaleOrigin';
import ProposalsReceiptGroups from '../pages/ProposalsTrack/ProposalsReceiptGroups/ProposalsReceiptGroups';
import ProposalsReceiptFieldsBase from '../pages/ProposalsTrack/ProposalsReceiptFieldsBase/ProposalsReceiptFieldsBase';
import ProposalsPromoters from '../pages/ProposalsTrack/ProposalsPromoters/ProposalsPromoters';
import Commission from "../pages/Commissioning/Commission/Commission";
import TransferToDo from "../pages/Commissioning/TransferToDo/TransferToDo";
import TransferDone from "../pages/Commissioning/TransferDone/TransferDone";
import Reports from '../pages/Reports/Reports';
import LeadsOnly from '../pages/Leads/LeadsOnly/LeadsOnly';
import LeadsAll from '../pages/Leads/LeadsAll/LeadsAll';
import CampaignsCustomerPortfolio from '../pages/Campaigns/CampaignsCustomerPortfolio/CampaignsCustomerPortfolio';
import LeadsErrors from '../pages/Leads/LeadsErrors/LeadsErrors';
import CampaignsCustomerPortfolioDetails
    from '../pages/Campaigns/CampaignsCustomerPortfolioDetails/CampaignsCustomerPortfolioDetails';
import UsersEcorban from '../pages/UsersEcorban/UsersEcorban';
import RankingReport from "../pages/Reports/RankingReport/RankingReport";
import LeadsCategories from '../pages/Leads/LeadsCategories/LeadsCategories';
import ProposalsCategories from "../pages/ProposalsTrack/ProposalsCategories/ProposalsCategories";
import DashboardReport from "../pages/Reports/DashboardReport/DashboardReport";
import RankingReportView from "../pages/Reports/RankingReport/RankingReportView/RankingReportView";
import DashboardReportView from "../pages/Reports/DashboardReport/DashboardReportView/DashboardReportView";
import SimulationList from "../pages/Simulations/SimulationList/SimulationList";
import ProductsConfigurations from "../pages/Products/Configurations/ProductsConfigurations";
import NonUserGroup from "../pages/Campaigns/NonUserGroup/NonUserGroup";
import SimulationInsurance from "../pages/Simulations/SimulationInsurance/SimulationInsurance";
import LeadsRedirectPages from "../pages/Leads/LeadsRedirectPages/LeadsRedirectPages";
import CommissionPreview from "../pages/Commissioning/CommissionPreview/CommissionPreview";
import NotificationAutomation from "../pages/NotificationAutomation/NotificationAutomation";

const RootRoute = () => {
    const queryClient = new QueryClient();

    return (
        <BrowserRouter>
            <QueryClientProvider client={queryClient}>
                <StyleProvider>
                    <AuthProvider>
                        <Routes>
                            <Route path="/" element={<Login/>}/>
                            <Route path="/login" element={<Login/>}/>

                            <Route element={<AuthenticationGate/>}>
                                <Route path="/home" element={<Home/>}/>

                                <Route path="leads">
                                    <Route path="all" element={<LeadsAll/>}/>
                                    <Route path="list" element={<LeadsOnly/>}/>

                                    <Route path="errors">
                                        <Route path="list" element={<LeadsErrors/>}/>
                                    </Route>

                                    <Route path="categories">
                                        <Route path="list" element={<LeadsCategories/>}/>
                                    </Route>

                                    <Route path="redirect-pages" element={<LeadsRedirectPages/>}/>
                                </Route>

                                <Route path="produtos">
                                    <Route path="list" element={<Produtos/>}/>
                                    <Route path="configurations" element={<ProductsConfigurations/>}/>
                                </Route>

                                <Route path="clients">
                                    <Route path="list" element={<ClientsUsers/>}/>

                                    <Route path="ecorban">
                                        <Route path="list" element={<UsersEcorban/>}/>
                                    </Route>

                                    <Route path="logs">
                                        <Route path="list" element={<LogsAuth/>}/>
                                    </Route>
                                </Route>

                                <Route path="cashback">
                                    <Route path="list" element={<Cashback/>}/>
                                    <Route path="rescue" element={<CashbackRescue/>}/>
                                    <Route path="configuration" element={<CashbackConfiguration/>}/>
                                </Route>

                                <Route path="app">
                                    <Route path="versions" element={<AppVersion/>}/>
                                </Route>

                                <Route path="admin">
                                    <Route path="perfil-acesso" element={<ProfileAccess/>}/>
                                    <Route path="usuario" element={<User/>}/>
                                </Route>

                                <Route path="customer-capture">
                                    <Route path="list" element={<CustomerCapture/>}/>
                                </Route>

                                <Route path="campaigns">
                                    <Route path="list" element={<Campaigns/>}/>
                                    <Route path="user-group" element={<UserGroup/>}/>
                                    <Route path="non-user-group" element={<NonUserGroup/>}/>
                                    <Route path="customer-portfolio">
                                        <Route path="list" element={<CampaignsCustomerPortfolio/>}/>
                                        <Route path="list/:id" element={<CampaignsCustomerPortfolioDetails/>}/>
                                    </Route>
                                </Route>

                                <Route path="help-articles">
                                    <Route path="list" element={<HelpArticles/>}/>
                                </Route>

                                <Route path="proposal-track">
                                    <Route path="list-error" element={<ProposalsTrackError/>}/>
                                    <Route path="list" element={<ProposalTrackList/>}/>

                                    <Route path="covenants-tables">
                                        <Route path="list" element={<ProposalsCovenantsTable/>}/>
                                    </Route>

                                    <Route path="covenants">
                                        <Route path="list" element={<ProposalsCovenants/>}/>

                                        <Route path="groups">
                                            <Route path="list" element={<ProposalsCovenantsGroups/>}/>
                                        </Route>
                                    </Route>

                                    <Route path="operations">
                                        <Route path="list" element={<ProposalsOperations/>}/>
                                    </Route>

                                    <Route path="status">
                                        <Route path="list" element={<ProposalsStatus/>}/>
                                    </Route>

                                    <Route path="sale-origin">
                                        <Route path="list" element={<ProposalsSaleOrigin/>}/>
                                    </Route>

                                    <Route path="promoters">
                                        <Route path="list" element={<ProposalsPromoters/>}/>
                                    </Route>

                                    <Route path="receipt">
                                        <Route path="groups">
                                            <Route path="list" element={<ProposalsReceiptGroups/>}/>
                                        </Route>
                                        <Route path="fields-base">
                                            <Route path="list" element={<ProposalsReceiptFieldsBase/>}/>
                                        </Route>
                                    </Route>

                                    <Route path="categories">
                                        <Route path="list" element={<ProposalsCategories/>}/>
                                    </Route>
                                </Route>

                                <Route path="commissioning">
                                    <Route path="commission/list" element={<Commission/>}/>
                                    <Route path="transfers-to-do/list" element={<TransferToDo/>}/>
                                    <Route path="transfers-done/list" element={<TransferDone/>}/>
                                    <Route path="ranking-report" element={<RankingReport/>}/>
                                    <Route path="commission-preview" element={<CommissionPreview/>}/>
                                </Route>

                                <Route path="companys">
                                    <Route path="list" element={<Company/>}/>
                                </Route>

                                <Route path="reports">
                                    <Route path="list" element={<Reports/>}/>
                                    <Route path="ranking">
                                        <Route path="" element={<RankingReport/>}/>
                                        <Route path="view" element={<RankingReportView/>}/>
                                    </Route>
                                    <Route path="dashboard">
                                        <Route path="" element={<DashboardReport/>}/>
                                        <Route path="view" element={<DashboardReportView/>}/>
                                    </Route>
                                </Route>

                                <Route path="simulations">
                                    <Route path="list" element={<SimulationList/>}/>
                                    <Route path="insurance-list" element={<SimulationInsurance/>}/>
                                </Route>

                                <Route path="notification-automation">
                                    <Route path="list" element={<NotificationAutomation/>}/>
                                </Route>

                                <Route path='unauthorized' element={<Unauthorized/>}/>
                            </Route>

                            <Route path="*" element={<Page404/>}/>
                        </Routes>
                    </AuthProvider>
                </StyleProvider>
            </QueryClientProvider>
        </BrowserRouter>
    );
}

export default RootRoute;

