import moment from "moment";
import * as yup from "yup";
import {validateCNPJ, validateEmail, validatePhone} from "validations-br";

export const schema = yup.object().shape({
    corporateName: yup
        .string()
        .required()
        .min(3)
        .max(100),
    name: yup
        .string()
        .required()
        .min(3)
        .max(100),
    cnpj: yup
        .string()
        .required()
        .max(18).test('invalid', 'CNPJ inválido', (value: string | undefined) => validateCNPJ(value || '')),
    hostname: yup
        .string()
        .required(),
    colorPrimary: yup
        .string()
        .required(),
    colorSecondary: yup
        .string()
        .required(),
    email: yup
        .string()
        .required()
        .test('invalid', 'E-mail inválido', (value: string | undefined) => validateEmail(value || '')),
    password: yup
        .string()
        .when(
            'id',
            {
                is: (item: any) => !!item?.value,
                then: yup
                    .number()
                    .required()
            }
        ),
    mailAddress: yup
        .string()
        .required()
        .max(150)
        .test('invalid', 'E-mail inválido', (value: string | undefined) => validateEmail(value || '')),
    phone: yup
        .string()
        .required()
        .test("invalid", "Telefone é inválido", (value: string | undefined) => validatePhone(value || '')),
    mailHost: yup
        .string()
        .max(255),
    mailPort: yup
        .string()
        .max(6),
    mailEncryption: yup
        .string(),
    mailUsername: yup
        .string()
        .max(100),
    mailPassword: yup
        .string()
        .max(100),
    mailName: yup
        .string()
        .required()
        .max(100),
    role: yup
        .object()
        .required(),
});