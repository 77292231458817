import React, {Dispatch, ReactNode, SetStateAction, useState} from 'react';
import Swal from "sweetalert2";
import {useSimulation} from "./Typing/SimulationProvider";
import {useAuth} from "./AuthProvider";

interface ContextProps {
    currentStep: number,
    setCurrentStep: Dispatch<SetStateAction<number>>,
    handlePrev: () => void,
    handleNext: () => void,
    renderNav: (nextAction?: () => void, disabledNextAction?: boolean, handleExtra?: any, buttonTextExtra?: string, disabledPrevAction?: boolean) => JSX.Element,
    stepsLength: number,
    setStepsLength: Dispatch<SetStateAction<number>>,
}

export const MultiStepsContext = React.createContext<ContextProps>({} as ContextProps);

interface MultiStepsProviderProps {
    children: ReactNode,
}

export const MultiStepsProvider = ({children}: MultiStepsProviderProps) => {
    const [currentStep, setCurrentStep] = useState<number>(0);
    const [stepsLength, setStepsLength] = useState<number>(0);

    const handlePrev = () => setCurrentStep(currentStep - 1);

    const handleNext = () => setCurrentStep(currentStep + 1);

    const renderNav = (nextAction: any, disabledNextAction = false, handleExtra: any = undefined, buttonTextExtra: string = '', disabledPrevAction: boolean = false) => {
        return (
            <div className="button-row d-flex justify-content-between mt-4">
                {currentStep > 0 &&
                    <button
                        className="btn bg-gradient-light mb-0 js-btn-prev"
                        type="button"
                        title="Prev"
                        onClick={handlePrev}
                        disabled={disabledPrevAction}
                    >
                        Voltar
                    </button>
                }

                <div className={`d-flex gap-3 ${currentStep === 0 ? 'w-100' : ''}`}>
                    {handleExtra &&
                        <button className={`btn btn-outline-primary mb-0`} type="button"
                                onClick={handleExtra}>{buttonTextExtra}
                        </button>
                    }

                    {nextAction && (
                        <button className={`btn bg-gradient-dark mb-0 js-btn-next ${currentStep === 0 && 'ms-auto'}`}
                                type="button" title="Next"
                                onClick={nextAction}
                                disabled={disabledNextAction}>{currentStep === (stepsLength - 1) ? 'Finalizar' : 'Próximo'}</button>
                    )}
                </div>
            </div>
        );
    }

    return (
        <MultiStepsContext.Provider value={{
            currentStep,
            setCurrentStep,
            handlePrev,
            handleNext,
            renderNav,
            stepsLength,
            setStepsLength,
        }}>
            {children}
        </MultiStepsContext.Provider>
    );
}

export const useMultiSteps = () => React.useContext(MultiStepsContext);
